<template>
  <el-row class="table">
    <el-row class="row-bg">
      <el-date-picker
        v-model="dateTime"
        type="year"
        value-format="yyyy"
        class="mb-10 mr-10"
        @change="handleChange"
        placeholder="选择年份">
      </el-date-picker>
      <el-button class="tb-button" @click="search" type="primary">查 询</el-button>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="起止时间" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.min.substring(0, 10)}}</span> ~
          <span>{{scope.row.max.substring(0, 10)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="preview(scope.row)">查看课时详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

  </el-row>
</template>

<script>
import { getListSnapshot } from '@/api/salary'

export default {
  created() {
    let year = new Date().getFullYear()
    this.dateTime = year+''
    this.minStartTime = `${year}-01-01`
    this.maxStartTime = `${year}-12-31`
    this.getListSnapshot()
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      dateTime: '',
      minStartTime: '',
      maxStartTime: '',
    }
  },
  methods: {
    async getListSnapshot () {
      if (!this.minStartTime || !this.maxStartTime) {
        window.$msg('请选择起止时间', 2)
        return false
      }
      const res = await getListSnapshot({
        st: this.minStartTime + ' 00:00:00',
        et: this.maxStartTime + ' 23:59:59',
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    handleChange(val) {
      this.minStartTime = `${val}-01-01`
      this.maxStartTime = `${val}-12-31`
    },

    search() {
      this.pagination.currentPage = 1
      this.getListSnapshot()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListSnapshot()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListSnapshot()
    },

    // 查看报表
    preview(row) {
      this.$router.push({
        path: '/statistics/hour-statistics-details',
        query: {
          id: row.id
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
